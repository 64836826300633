import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import config from '../../config';
import {
  Page,
  SectionHero,
  SectionHowItWorks,
  SectionLocations,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  Button,
} from '../../components';
import { TopbarContainer } from '../../containers';
import EarningsCalculator from './EarningsCalculator';
import facebookImage from '../../assets/saunatimeFacebook-1200x630.jpg';
import twitterImage from '../../assets/saunatimeTwitter-600x314.jpg';
import InformationSection from './InformationSection';
import WeLoveSection from './WeLoveSection/WeLoveSection';
import css from './LandingPage.module.css';
import charityImage from './charity.jpg';
import coOffset from './coOffset.jpg';
import swapWithOthersImage from './swap_with_others.jpg';
import Grid from '@mui/material/Grid';
import { NamedLink } from '../../components';
import { height } from '@mui/system';
import BookingTypesSection from './BookingTypesSection/BookingTypesSection';
import StoreSection from './StoreSection/StoreSection';

export const LandingPageComponent = props => {
  const { history, intl, location, scrollingDisabled } = props;

  // Schema for search engines (helps them to understand what this page is about)
  // http://schema.org
  // We are using JSON-LD format
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'LandingPage.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'LandingPage.schemaDescription' });
  const schemaImage = `${config.canonicalRootURL}${facebookImage}`;

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
      twitterImages={[
        { url: `${config.canonicalRootURL}${twitterImage}`, width: 600, height: 314 },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer landingPage={true} />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          {/* <div className={css.secondTopbar}>

          <Button className={css.secTopbarButton} onClick={() => history.push('/s?pub_isProductForSale=true')}>
            Shop now
          </Button>

          <Button className={css.secTopbarButton} onClick={() => history.push('/s?pub_isProductForSale=false')}>
            Rent now
          </Button>

          <Button className={css.secTopbarButton} onClick={() => history.push('/')}>
            Sustainability
          </Button>

          <Button className={css.secTopbarButton} onClick={() => history.push('/')}>
            Journal
          </Button>

          </div> */}
          <div className={css.heroContainer}>
            <SectionHero className={css.hero} history={history} location={location} />
          </div>
          <ul className={css.sections}>
            <li className={css.section}>
              <div className={css.sectionContent}>
                <BookingTypesSection />
              </div>
            </li>

            <li className={css.section}>
              {typeof window !== 'undefined' && <WeLoveSection intl={intl} />}
            </li>

            {/* <li className={css.section}>
              <div className={css.sectionContent}>
                <InformationSection/>
              </div>
            </li>

           
           
            <li className={css.section}>
              <div className={css.sectionContentFirstChild}>
                <SectionLocations />
              </div>
            </li>
            <li className={css.section}>
              <div className={css.sectionContent}>
              <h1>Earnings calculator</h1>
                <EarningsCalculator />
              </div>
            </li> */}
            <li className={css.section}>
              <div className={css.sectionContent}>
                <SectionHowItWorks />
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <Grid container item xs={12}>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    direction="column"
                    justifyContent={'center'}
                    alignContent={'center'}
                  >
                    <p style={{ fontSize: '30px', marginBottom: '0', textTransform: 'uppercase' }}>
                      {/* {intl.formatMessage({ id: 'LandingPage.winterFashion' })} */}
                      {'Consign your unwanted luxury fashion'}
                    </p>

                    <p style={{ marginTop: '20px', paddingRight: '20px', lineHeight: '22px' }}>
                      {
                        'What Is a Charitable Donation? A charitable donation is a gift of cash or property made to a nonprofit organization to help it accomplish its goals, for which the donor receives nothing of value in return.'
                      }
                    </p>
                  </Grid>

                  <Grid container item xs={12} md={6}>
                    <div className={css.scrollBox}>
                      <NamedLink
                        className={css.spotlightImageWrapper}
                        name="ListingPage"
                        params={{
                          id: '63470f93-ee7a-4424-8706-4e69f518959d',
                          slug: 'donate-to-charity',
                        }}
                      >
                        <img className={css.spotlightImage} src={swapWithOthersImage} />
                      </NamedLink>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <Grid container item xs={12}>
                  <Grid container item xs={12} md={6} justifyContent="flex-start">
                    <div className={css.scrollBox}>
                      <NamedLink
                        className={css.spotlightImageWrapper}
                        name="ListingPage"
                        params={{
                          id: '6352232d-42dd-4664-b643-55f6ba60f535',
                          slug: 'offset-your-carbon-emissions',
                        }}
                      >
                        <img className={css.spotlightImage} src={coOffset} />
                      </NamedLink>
                    </div>
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    md={6}
                    direction="column"
                    justifyContent={'center'}
                    alignContent={'center'}
                  >
                    <p style={{ fontSize: '30px', marginBottom: '0', textTransform: 'uppercase' }}>
                      {/* {intl.formatMessage({ id: 'LandingPage.winterFashion' })} */}
                      {'Offset your carbon emissions'}
                    </p>

                    <p style={{ marginTop: '20px', paddingRight: '20px', lineHeight: '22px' }}>
                      {
                        'What is a Carbon Offset? A Carbon offset is a way to compensate for your emissions by funding an equivalent carbon dioxide saving elsewhere. Our everyday actions, at home and at work, consume energy and produce carbon emissions, such as driving, flying and heating buildings.'
                      }
                    </p>
                  </Grid>
                </Grid>
              </div>
            </li>

            <li className={css.section}>
              <div className={css.sectionContent}>
                <StoreSection />
              </div>
            </li>

            <li className={css.section}>
              <div class="elfsight-app-13771323-4ddb-42c1-8a88-8e1b46c8b543"></div>
            </li>
            {/* <li className={css.section}>
              <div className="elfsight-app-9840d8c0-8d4f-4b5e-9f14-7b75f21b028e"></div>
            </li> */}
          </ul>
        </LayoutWrapperMain>
        <LayoutWrapperFooter>
          <Footer />
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
    </Page>
  );
};

const { bool, object } = PropTypes;

LandingPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,

  // from withRouter
  history: object.isRequired,
  location: object.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  return {
    scrollingDisabled: isScrollingDisabled(state),
  };
};

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const LandingPage = compose(withRouter, connect(mapStateToProps), injectIntl)(LandingPageComponent);

export default LandingPage;
