import React from 'react';
import css from './BookingTypesSection.module.css';

import book1 from '../../../assets/book1.jpeg';
import book2 from '../../../assets/book2.jpeg';
import book3 from '../../../assets/swap_with_others.jpg';
import book4 from '../../../assets/give_back.jpg';

const BookingTypesSection = () => {
  return (
    <div className={css.wrapper}>
      <div
        className={css.item}
        style={{
          backgroundImage: `url(${book1})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        Give back to your community
      </div>

      <div
        className={css.item}
        style={{
          backgroundImage: `url(${book3})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        Swap with others
      </div>

      <div
        className={css.item}
        style={{
          backgroundImage: `url(${book2})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        Buy preloved fashion
      </div>

      <div
        className={css.item}
        style={{
          backgroundImage: `url(${book4})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        Rent from your wardrobe
      </div>
    </div>
  );
};

export default BookingTypesSection;
