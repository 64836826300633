import React from 'react';
import Grid from '@material-ui/core/Grid';
import pic1 from './images/pic1.jpg';
import pic2 from './images/pic2.jpg';
import pic3 from './images/pic3.jpg';
import pic4 from './images/pic4.jpg';
import pic5 from './images/pic5.jpg';

import css from './InformationSection.module.css';

function InformationSection() {
  return (
    <div>
        <Grid container item xs={12}>
            
            <Grid container item xs={12} style={{marginBottom: '2pc'}}>
                <Grid container item xs={12} md={4}>
                    <img src={pic1} className={css.image} /> 
                </Grid>

                <Grid container item xs={12} md={4} justifyContent='center' alignContent='center'>
                    <p className={css.textTitle}>Lorem Ipsum</p>
                    <p className={css.textBody}>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                </Grid>

                <Grid container item xs={12} md={4}>
                    <img src={pic2} className={css.image} /> 
                </Grid>
            </Grid>




            <Grid container item xs={12} style={{marginBottom: '2pc'}}>

                <Grid container item xs={12} md={4} justifyContent='center' alignContent='center'>
                    <p className={css.textTitle}>Lorem Ipsum</p>
                    <p className={css.textBody}>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                </Grid>

                <Grid container item xs={12} md={4}>
                    <img src={pic3} className={css.image} /> 
                </Grid>


                <Grid container item xs={12} md={4} justifyContent='center' alignContent='center'>
                    <p className={css.textTitle}>Lorem Ipsum</p>
                    <p className={css.textBody}>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                </Grid>
            </Grid>



            <Grid container item xs={12} style={{marginBottom: '2pc'}}>
                <Grid container item xs={12} md={4}>
                    <img src={pic4} className={css.image} /> 
                </Grid>

                <Grid container item xs={12} md={4} justifyContent='center' alignContent='center'>
                    <p className={css.textTitle}>Lorem Ipsum</p>
                    <p className={css.textBody}>Neque porro quisquam est qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit...</p>
                </Grid>

                <Grid container item xs={12} md={4}>
                    <img src={pic5} className={css.image} /> 
                </Grid>
            </Grid>
        </Grid>
    </div>
  )
}

export default InformationSection