import React from 'react';
import css from './StoreSection.module.css';

import storePic from './store.jpg';

const StoreSection = () => {
  return (
    <div className={css.wrapper}>
      <img src={storePic} className={css.storePic} />
      <div className={css.textWrapper}>
        <div className={css.textSection}>
          <h2>
            Come and visit Australia’s First Zero Waste Sustainable, Dress Hire & Consignment
            Boutique.
          </h2>
        </div>
        <div className={css.textSection}>
          <h2>14 William st, Paddington, NSW, 2021.</h2>
          <p>
            Opening Hours:
            <br />
            Tuesday to Saturday: 10:00am - 5:30pm.
            <br />
            Sunday: By appointment only
            <br />
            Monday: By appointment only
          </p>
        </div>
      </div>
    </div>
  );
};

export default StoreSection;
