import React, {useState, useEffect} from 'react'
import Select from 'react-select';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import css from './EarningsCalculator.module.css';


function EarningsCalculator(props) {

    const [designer, setDesigner] = useState({ value: 'Christian Lacroix', label: 'Christian Lacroix' });
    const [condition, setCondition] = useState(50);
    const [age, setAge] = useState(50);


    const options = [
        { value: 'Christian Dior', label: 'Christian Dior' },
        { value: 'Christian Lacroix', label: 'Christian Lacroix' },
        { value: 'Dolce & Gabbana', label: 'Dolce & Gabbana' },
        { value: 'Gabrielle Coco Chanel', label: 'Gabrielle Coco Chanel' },
        { value: 'Gianni Versace', label: 'Gianni Versace' },
        { value: 'Giorgio Armani', label: 'Giorgio Armani' },
        { value: 'Guccio Gucci', label: 'Guccio Gucci' },
        { value: 'Other', label: 'Other' },
      ];

      const handleChangeDesigner = (selectedOption) => {
        setDesigner(selectedOption)
      }

      const conditionOptions = [
        {
          value: 0,
          label: 'Very used',
        },
        {
          value: 25,
          label: 'Used',
        },
        {
          value: 50,
          label: 'Moderately used',
        },
        {
          value: 75,
          label: 'Barely used',
        },
        {
          value: 100,
          label: 'New',
        },
      ];

      function valuetext(value) {
        return `${value}°C`;
      }
      
      function valueLabelFormat(value) {
        return conditionOptions[conditionOptions.findIndex((conditionOptions) => conditionOptions.value === value)]?.label;
      }


      const ageOptions = [
        {
          value: 0,
          label: '< 1 month',
        },
        {
          value: 25,
          label: '1-6 months',
        },
        {
          value: 50,
          label: '1 year',
        },
        {
          value: 75,
          label: '1-3 years',
        },
        {
          value: 100,
          label: '> 3 years',
        },
      ];

      function valuetextAge(value) {
        return `${value}°C`;
      }
      
      function valueLabelFormatAge(value) {
        return ageOptions[ageOptions.findIndex((ageOptions) => ageOptions.value === value)]?.label;
      }

    const designerValue = options.findIndex((options) => options.value === designer.value) * 30;
    const min = (30 * (14 - age / 10 + condition / 10)) + designerValue;
    const max = min + 500 + (14 - age / 10 + condition / 10) + designerValue;


    return (
        <div className={css.sectionWrapper}>
           


            <div className={css.field}>
                    <span className={css.label}>
                        Designer
                    </span>

                    <Select
                            className={css.select}
                            value={designer}
                            onChange={handleChangeDesigner}
                            options={options}
                        />

            </div>

            <div className={css.field}>

                    <span className={css.label}>
                        Condition
                    </span>

                    <Box sx={{ width: 500 }}>
                    <Slider
                        className={css.slider}
                        aria-label="Condition values"
                        defaultValue={50}
                        valueLabelFormat={valueLabelFormat}
                        getAriaValueText={valuetext}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={conditionOptions}
                        value={condition}
                        onChange={(event, newValue) => {
                            setCondition(newValue)
                        }}
                    />
                    </Box>
            </div>


            <div className={css.field}>

                    <span className={css.label}>
                        How old is it
                    </span>

                    <Box sx={{ width: 500 }}>
                    <Slider
                        className={css.slider}
                        aria-label="Age values"
                        defaultValue={50}
                        valueLabelFormat={valueLabelFormatAge}
                        getAriaValueText={valuetextAge}
                        step={null}
                        valueLabelDisplay="auto"
                        marks={ageOptions}
                        value={age}
                        onChange={(event, newValue) => {
                            setAge(newValue)
                        }}
                    />
                    </Box>
            </div>

            <div className={css.field}>

                    <span className={css.label}>
                        Monthly earnings
                    </span>

                   <span className={css.aproxAmountLabel}>{`$${parseInt(min)} - $${parseInt(max)}`}</span>
            </div>
            
            
        </div>
    )
}

export default EarningsCalculator
